var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('br'),_c('b-tabs',{staticClass:"mt-3",model:{value:(_vm.tab_index),callback:function ($$v) {_vm.tab_index=$$v},expression:"tab_index"}},[_c('b-tab',{attrs:{"title":`${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        true,
        'Asignaturas'
      )}`}},[_c('MatterList',{staticClass:"mt-4",attrs:{"allows_crud":_vm.allows_crud}})],1),_c('b-tab',{attrs:{"title":_vm.$getVisibleNames('teaching.matter', true, 'Asignaturas Base')}},[(_vm.load_old_matter)?[_c('OldMatterAltList',{staticClass:"mt-4",attrs:{"allows_crud":_vm.allows_crud}})]:_vm._e()],2),(
        _vm.institution &&
        _vm.institution.internal_use_id == 'duoc_uc' &&
        ((_vm.user_position &&
          [1, 2].includes(_vm.user_position.position) &&
          [1, 2].includes(_vm.user.groups[0])) ||
          _vm.user.is_superuser)
      )?_c('b-tab',{attrs:{"title":_vm.$getVisibleNames(
          'teaching.duocsapmatter',
          true,
          'Asignaturas creadas en SAP'
        )}},[_c('DuocSapMatterList',{staticClass:"mt-4",attrs:{"allows_crud":_vm.allows_crud}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }